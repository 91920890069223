import SurveyCookiesMainImg from '../../assets/images/portfolio/surveyCookiesMain.png';

export const surveyCookies = {
  title: 'Survey Cookies',
  subtitle:
    'Survey Cookies is an application that is able to create surveys on multiple recipients. Furthermore, the application equips with Google authentication and payment system as parts of its backend utilities.',
  contents: `<img src=${SurveyCookiesMainImg} />
    <div>
      <p>Survey Cookies is an application developed using the MERN framework. Users can create surveys with YES/NO questions and send them to multiple recipients.</p>
      <br />
      <p>The application also includes authentication and payment systems, and users can perform CRUD operations with data stored in MongoDB Atlas.</p>

      <br />
      <p style="font-weight: bold">Open source kits and API</p>
      <br />
      <p style="font-weight: bold">Front End</p>
      <ul>
        <li style="padding-left:1em">• MaterializeCSS: a front-end framework to help create responsive websites</li>
        <li style="padding-left:1em">• Google Fonts: typographic assets for building customized front-end font style</li>
        <li style="padding-left:1em">• React: a JS library for building user interfaces</li>
        <li style="padding-left:1em">• ReactDOM: DOM handler for React application</li>
        <li style="padding-left:1em">• Redux: a predicatable state container for JavaScript applications</li>
      </ul>
      <br />
      <p style="font-weight: bold"p>Back End</p>
      <ul>
        <li style="padding-left:1em">• node.js, express.js: serve the backend services</li>
        <li style="padding-left:1em">• passport: to add authentication mechanism, such as register and login functionalities</li>
        <li style="padding-left:1em">• Mongoose: the ODM package of MongoDB; serve as the website's database solution</li>
        <li style="padding-left:1em">• SendGrid: a cloud-based email delivery platform for transactional and marketing email</li>
        <li style="padding-left:1em">• cookie-session: a simple cookie-based session middleware</li>
        <li style="padding-left:1em">• react-router: to process navigational actions in the application</li>
        <li style="padding-left:1em">• axios: a promise based HTTP client for the browser and node.js</li>
      </ul>

      <br />
      <p style="font-weight: bold">External Link</p>
      <ul>
      <li style="padding-left:1em">• <a href="https://survey-cookies-production.up.railway.app/">Deployment</a></li>
      <li style="padding-left:1em">• <a href="https://github.com/weijchen/survey-cookies">github</a></li>
      </ul>
    </div>`,
  tags: ['web'],
  url: 'survey-cookies',
  image: SurveyCookiesMainImg,
  timeStamp: '2021-9-23',
};
