import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import SocialFacebookIcon from '../../assets/icons/socialFacebook.svg'
import SocialGithubIcon from '../../assets/icons/socialGithub.svg'
import SocialItchIcon from '../../assets/icons/socialItch.svg'
import SocialLinkedinIcon from '../../assets/icons/socialLinkedin.svg'
import SocialTwitterIcon from '../../assets/icons/socialTwitter.svg'

function Footer(props) {
  return (
    <>
      <div className="__footer container-fluid d-flex">
        <div className="__footer__container col-12">
          <div className="__footer__title">Jimmy Chen</div>
          <div className="__footer__desc">
            <Link to="/" className="__footer__desc-link">
              <p>About</p>
            </Link>
            <Link to="/portfolio" className="__footer__desc-link">
              <p>Portfolio</p>
            </Link>
          </div>
          <div className="__footer__sociallink container-fluid">
            <div className="row m-auto">
              <a href="https://www.linkedin.com/in/jimmyweicc/" className="col">
                <img src={SocialLinkedinIcon} alt="social-linkedin" />
              </a>
              <a href="https://github.com/weijchen" className="col">
                <img src={SocialGithubIcon} alt="social-github" />
              </a>
              {/* <a href="https://weijchen0919.itch.io/" className="col">
                <img src={SocialItchIcon} alt="social-itch" />
              </a> */}
              {/* <a href="https://www.facebook.com/weijchen2013" className="col">
                <img src={SocialFacebookIcon} alt="social-facebook" />
              </a>
              <a href="https://twitter.com/weijchen0919" className="col">
                <img src={SocialTwitterIcon} alt="social-twitter" />
              </a> */}
            </div>
          </div>
          <div className="__footer__copyright">
            <p> &copy; 2023 Jimmy Chen </p>
          </div>
        </div>
      </div>
    </>
  )
}

Footer.propTypes = {}

export default Footer
