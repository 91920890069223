import TodolistMainImg from '../../assets/images/portfolio/todolistMain.png';

export const todolist = {
  title: 'Todo List',
  subtitle: 'In-browser todo-list with star and delete functions',
  contents: `<img src=${TodolistMainImg} /><div>In-browser todo-list with star and delete functions<div>`,
  tags: ['web'],
  url: 'todo-list',
  image: TodolistMainImg,
  timeStamp: '2021-9-23',
};
