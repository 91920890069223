import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ArrowUpDark from '../../assets/icons/arrowUpDark.svg'
import ArrowDownDark from '../../assets/icons/arrowDownDark.svg'

function CusAccordian(props) {
  const { title, subtitle, time, desc } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <details onClick={handleClick}>
      <summary>
        <span className="summary-title" dangerouslySetInnerHTML={{ __html: `${title}` }}></span>
        <div className="summary-subtitle d-flex">
          <span className="subtitle" dangerouslySetInnerHTML={{ __html: `${subtitle}` }}></span>
          <span className="time">{time}</span>
        </div>

        <div className="summary-icon">
          <img src={isOpen ? ArrowUpDark : ArrowDownDark} />
        </div>
      </summary>
      <div className="summary-content" dangerouslySetInnerHTML={{ __html: `${desc}` }} />
    </details>
  )
}

CusAccordian.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}

export default CusAccordian
