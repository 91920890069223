import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CusAccordian from '../../UI/CusAccordian';

function EduAndExp() {
  return (
    <>
      <div className="__edu-exp cus-container d-flex">
        <div className="__edu-exp__education col-12 col-md-6">
          <div className="__edu-exp__title">
            <p>Education</p>
          </div>
          <div className="__edu-exp__contents d-flex">
            <div className="__education-card col-12">
              <div className="title">Carnegie Mellon University, School of Computer Science</div>
              <div className="time">Aug. 21 - May 23</div>
              <div className="contents">Master of Entertainment Technology</div>
            </div>
            <div className="__education-card col-12">
              <div className="title">National Taiwan University</div>
              <div className="time">Sept. 11 - June 15</div>
              <div className="contents">Bachelor of Arts in Economics</div>
            </div>
          </div>
        </div>

        <div className="__edu-exp__experience col-12 col-md-6">
          <div className="__edu-exp__title">
            <p>Experience</p>
          </div>
          <div className="__edu-exp__contents d-flex">
            <CusAccordian
              title="<a href='https://www.intel.com/content/www/us/en/homepage.html'>Intel Corporation</a>"
              subtitle="Software Engineer, Internship"
              time="Jan. 2023 - Apr. 2023"
              desc="<ul>
              <li>•	Constructed automation tools with Python Subprocess and Bash scripting, leading to a 75% reduction in manual effort</li> 
              <li>• Implemented over 5 internal data pipelines for analyzing graphic data, including system utilization and driver performance</li>
              </ul>"
            />
            <CusAccordian
              title="<a href='https://www.alice.org/'>The Alice Project, Carnegie Mellon University</a>"
              subtitle="Software Engineer"
              time="Jan. 2022 - Dec. 2022"
              desc="<ul>
              <li>•	Contributed to the latest release of an educational game engine using Java, C#, and Unity, achieved 4000+ downloads per month</li> 
              <li>• Developed a custom textures rendering system using OpenGL and integrated Java code to rebuild a responsive camera system</li>
              </ul>"
            />
            <CusAccordian
              title="<a href='https://www.hioscar.com/'>Oscar Health</a>"
              subtitle="Ful Stack Engineer, Internship"
              time="May 2022 - Aug. 2022"
              desc="<ul>
              <li>•	Designed and launched an internal tests generation system for clinical teams, enabling the creation of 100+ testing suites with 40% less time. This system utilizes gRPC and Protobuf to communicate with other microservices and employs BigQuery and PostgreSQL as the persistent layer</li> 
              <li>• Built and maintained 10+ microservices and Cron jobs that were deployed on Apache Aurora</li>
              <li>• Created more than 20 mock objects with Python unittest to ensure the delivery of high-quality software</li>
              </ul>"
            />
            <CusAccordian
              title="<a href='http://www.3drens.com/'>3drens</a>"
              subtitle="Fronted Engineer"
              time="Oct. 2020 - May 2021"
              desc="<ul>
              <li>• Built a content management system using React, Redux, and Material UI for an automobile client with over 700K users</li>
              <li>• Identified and rectified code redundancy in the authorization module, resulting in approximately a 50% reduction in code</li>
              <li>• Developed content marketing tools (notification, promotion) for a landing page <a href='https://www.yoxi.app/'>[link]</a> serving over 3K daily users. Created reusable React components such as custom hooks and responsive components, reducing the time to create marketing events by ~25%</li>
              <li>• Utilized React components, Redux, and Bootstrap to refine an e-commerce site <a href='https://www.es2move.com/'>[link]</a> and boosted its usage rates by 60%</li>
              </ul>"
            />
            <CusAccordian
              title="<a href='https://snaclab.citi.sinica.edu.tw/'>Research Center for Information Technology Innovation (CITI), Academia Sinica</a>"
              subtitle="Research Assistant and Data Engineer, <a href='https://snaclab.citi.sinica.edu.tw/'>SNAC Lab</a>"
              time="Jan. 17 - Jul. 20"
              desc="<ul>
              <text style='font-weight: bold'>Understanding User Behavior in a Cross-domain Scenario</text>
              <li>• Established a query suggestion system analyzing users’ behavior of when they changed browsing domains</li>
              <li>• Designed a critical ingestion pipeline to process e-commerce data from Verizon Media using Spark and Pandas</li>
              <li>•	Developed novel embedding mechanism which collects time-/cross- domain features and conducted experiments via deep learning framework (RNN-based, Attention) using TensorFlow and PyTorch</li>
              </ul>
              <ul>
              <text style='font-weight: bold'>Query Forecasting: Identifying Critical Learning Queries</text>
              <li>• Analyzed data from Verizon Media’s e-commerce platform, which has millions of active users, constructed pipelines for analyzing the textual embedding using Python, Shell scripts, and established modeling tools</li>
              <li>• Proposed an attention-based model using PyTorch to discern purchasing intention, results were published at SIGIR eCOM 2019 <a href='http://ceur-ws.org/Vol-2410/paper32.pdf'>[paper]</a></li>
              </ul>"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EduAndExp;
