import FindColorMainImg from '../../assets/images/portfolio/findColorMain.png';

export const colorFinding = {
  title: 'Color Finding',
  subtitle: 'Can you find which grid is different from others?',
  contents: `<img src=${FindColorMainImg} /><div>Can you find which grid is different from others?<div>`,
  tags: ['game', 'web'],
  url: 'color-finding',
  image: FindColorMainImg,
  timeStamp: '2021-9-23',
};
