import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../../components/Portfolio/Portfolio'

function Portfolio(params) {
  return <Layout />
}

Portfolio.propTypes = {}

export default Portfolio
