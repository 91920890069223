import PatatapMainImg from '../../assets/images/portfolio/patatapMain.png';

export const patatapApp = {
  title: 'Patatap App',
  subtitle: 'Press any key and enjoy the tones. Try to make melodies on your own.',
  contents: `<img src=${PatatapMainImg} /><div>Press any key and enjoy the tones. Try to make melodies on your own.<div>`,
  tags: ['game', 'web'],
  url: 'patatap-app',
  image: PatatapMainImg,
  timeStamp: '2021-9-23',
}
