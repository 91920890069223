import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';

import CloseIcon from '../../assets/icons/close.svg';
import MenuIcon from '../../assets/icons/menu.svg';
import ArrowRightIcon from '../../assets/icons/arrowRight.svg';

const classNames = require('classnames');

function Header(props) {
  const [isHeaderAtTop, setIsHeaderAtTop] = useState(true);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [expandedLinks, setExpandedLinks] = useState(false);
  const toggleLinks = () => {
    setExpandedLinks(!expandedLinks);
  };

  const burgerBtnOnClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY >= 60) {
        setIsHeaderAtTop(false);
      } else {
        setIsHeaderAtTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHeaderAtTop]);

  return (
    <>
      <div className="container-fluid">
        {/* Laptop navbar */}
        <Navbar expand="lg" fixed="top" className={classNames('__header', isHeaderAtTop ? '' : 'active')}>
          <div className="__navbar__laptop container-fluid">
            <div className="__navbar__laptop__logo col-6 p-0">
              <Link to="/">
                <text>Jimmy Chen</text>
              </Link>
            </div>
            <div className="__navbar__laptop__functions col-6 p-0">
              <div className="__navbar__laptop__functions-about">
                <Link to="/">
                  <text>About</text>
                </Link>
              </div>
              <div className="__navbar__laptop__functions-portfolio">
                <Link to="/portfolio">
                  <text>Portfolio</text>
                </Link>
              </div>
            </div>
          </div>

          {/* Mobile navbar */}
          <div className="row __navbar__mobile m-0">
            <div className={classNames('__navbar__mobile__logo', !isHeaderAtTop && 'top')}>
              <Link to="/">
                <text>Jimmy Chen</text>
              </Link>
            </div>

            <div className="__navbar__mobile__menu" onClick={burgerBtnOnClick}>
              <img src={isBurgerMenuOpen ? CloseIcon : MenuIcon} alt="menu-icon" />
            </div>
            <CSSTransition in={isBurgerMenuOpen} timeout={300} classNames="visible" unmountOnExit>
              <div>
                <li className="sidebar-link" onClick={() => setIsBurgerMenuOpen(false)}>
                  <NavLink to="/">About</NavLink>
                </li>
                <li className="sidebar-link" onClick={() => setIsBurgerMenuOpen(false)}>
                  <NavLink to="/portfolio">Portfolio</NavLink>
                </li>
                {[1, 2, 3, 4].map((ele, ind) => (
                  <li className="sidebar-link" onClick={() => setIsBurgerMenuOpen(false)}>
                    <NavLink to="" hidden />
                  </li>
                ))}
              </div>
            </CSSTransition>
          </div>
        </Navbar>
      </div>
    </>
  );
}

Header.propTypes = {};

export default Header;
