import ARviationCover from '../../assets/images/portfolio/arviation/0_cover.png';
import ARviationOutside from '../../assets/images/portfolio/arviation/1_outside.JPG';
import ARviationInside from '../../assets/images/portfolio/arviation/2_inside.JPG';
import ARviationHunting from '../../assets/images/portfolio/arviation/4_hunting.JPG';
import ARviationAssembly from '../../assets/images/portfolio/arviation/7_assembly.JPG';
import ARviationFlying from '../../assets/images/portfolio/arviation/11_flying.JPG';
import ARviationPlaytestOne from '../../assets/images/portfolio/arviation/3_playtest_1.JPG';
import ARviationPlaytestTwo from '../../assets/images/portfolio/arviation/9_playtest_2.JPG';
import ARviationPlaytestThree from '../../assets/images/portfolio/arviation/10_playtest_3.JPG';
import ARviationFacultyOne from '../../assets/images/portfolio/arviation/5_faculty_1.JPG'
import ARviationFacultyTwo from '../../assets/images/portfolio/arviation/6_faculty_2.JPG'
import ARviationPhysicalMaterials from '../../assets/images/portfolio/arviation/8_physical_materials.JPG'
import ARviationPlaneColor from '../../assets/images/portfolio/arviation/12_plane_color.png'
import ARviationPlaneBlack from '../../assets/images/portfolio/arviation/13_plane_black.png'
import ARviationHalfsheetBack from '../../assets/images/portfolio/arviation/14_halfsheet_b.png'
import ARviationHalfsheetFront from '../../assets/images/portfolio/arviation/15_halfsheet_f.png'

export const arviation = {
  title: 'BAM! - Build A Mustang',
  subtitle: "Aviation x Education x AR Technology",
  contents: `<img id="cover" src=${ARviationCover} />
    <div>
      <p>This is an augmented reality project located in the Center for Aviation Technology and Training (CATT) in Pittsburgh. The project is designed to complement the Tuskegee Airmen exhibition and immerse children in the world of aviation.</p>
      <br />
      <p>Inspired by the history of the Tuskegee Airmen and the content of CATT, the goal of this project is to use augmented reality technologies to create an experience that complements a museum exhibit and sparks the interest of visiting school children in careers within the diverse field of aviation.</p>

      <br />
      <ul>
        <li style="padding-left:1em">• Project Duration: 14 weeks (Jan. 2022 - May 2022)</li>
        <li style="padding-left:1em">• Tools: Unity, Unity ARFoundation, XCode</li>
        <li style="padding-left:1em">• Platform: iOS</li>
        <li style="padding-left:1em">• Role: Programmer</li>
      </ul>
      <hr />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/QG-mnDNNkYQ" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Trailer</p>
      </div>

      <div style="padding: 24px; text-align: center">
        <img src=${ARviationHalfsheetFront} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ARviationHalfsheetBack} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <p style="font-size: 18px;">Halfsheet created by our UIUX team</p>
      </div>

      <p style="font-weight: bold">Notable Contributions</p>
      <ol>
        <li style="padding-left:1em">1. Implemented features of the application such as components selection, augmented reality component hunting, component assembly games, and narrator mechanism with Unity to provide students with an immersive experience.</li> 
        <li style="padding-left:1em">2. Developed the application with Unity Framework, collaborated with artists and UIUX designers, and utilized XCode to build and deploy the application on iOS devices.</li>
        <li style="padding-left:1em">3. Communicated with our client and participated in several playtesting sessions to help facilitate the project's progress.</li>
      </ol>

      <div style="padding: 24px; text-align: center">
        <img src=${ARviationOutside} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ARviationInside} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <p style="font-size: 18px;">Center for Aviation Technology and Training (CATT) in Pittsburgh</p>
      </div>

      <br />
      <p style="font-weight: bold">In this experience, we design multiple parts for players to explore, including components selection, augmented reality component hunting, component assembly, and flying in the virtual space.</p>
      <div style="padding: 24px; text-align: center">
        <img src=${ARviationPhysicalMaterials} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <p style="font-size: 18px;">Physical materials including airplane guid, morse code sheet, and a box of component cards</p>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${ARviationHunting} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ARviationAssembly} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <div style="text-align: center;">
          <iframe width="480" height="315" src="https://www.youtube.com/embed/uiDi6yIacXE" frameborder="0" allowfullscreen></iframe>
          <iframe width="480" height="315" src="https://www.youtube.com/embed/_fpQx90JWak" frameborder="0" allowfullscreen></iframe>
        </div>
        <p style="font-size: 18px;">Hunting and Assembly moments</p>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${ARviationFlying} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <iframe width="480" height="315" src="https://www.youtube.com/embed/-wJzzAPOtzo" frameborder="0" allowfullscreen style="vertical-align: middle;"></iframe>
        <p style="font-size: 18px;">Flying the P-51 Mustang in the virtual space</p>
      </div>

      <br />
      <p style="font-weight: bold">Throughout the semester, we invited several groups and people to playtest our experience. With many great suggestions and feedbacks, we are able to improve our application and implement features that are missing.</p>
      <div style="padding: 24px; text-align: center">
        <img src=${ARviationPlaytestOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <p style="font-size: 18px;">Playtest with children at Assemble, a community space for arts + technology</p>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${ARviationPlaytestTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ARviationPlaytestThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <p style="font-size: 18px;">Children at Hosanna House playing our experience in groups</p>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${ARviationFacultyOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ARviationFacultyTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <p style="font-size: 18px;">Jesse Schell, an ETC faculty sharing his perspective with the team</p>
      </div>

      <br />
      <div style="width: 100%;">
        <p style="font-weight: bold">External Link</p>
        <ul>
          <li style="padding-left:1em">• <a href="https://projects.etc.cmu.edu/ar-viation/">project website</a></li>
          <li style="padding-left:1em">• <a href="https://github.com/ARviation/ARviation">github</a></li>
        </ul>
      </div>
    </div>`,
  tags: ['game', 'project'],
  url: 'bam-build-a-mustang',
  image: ARviationCover,
  timeStamp: '2022-05-31',
};
