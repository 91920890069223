import ArtisanCover from '../../assets/images/portfolio/artisan/logo.jpg';
import ArtisanHalfsheetBack from '../../assets/images/portfolio/artisan/artisanHalfsheetBack.png';
import ArtisanHalfsheetFront from '../../assets/images/portfolio/artisan/artisanHalfsheetFront.png';

export const artisan = {
  title: 'Artisan',
  subtitle: 'Procedural Generation Pipeline X Open World with Tang Dynasty',
  contents: `<img id="cover" src=${ArtisanCover} />
    <div>
      <p>This project is to design and build a procedural generation pipeline with authentic historical data. As one of the main enabling technologies for open world game creation, this pipeline should provide complete and reusable solutions for large-scale cityscape development and world design, ultimately not only for Tang Dynasty, but also for other time periods and cultural themes as needed.</p>
      <br />
      <p>Using Houdini FX and Unreal Engine 5, the pipeline can be summarized as the following stages: building modules and parameterization in Houdini, generating single buildings, and implementing building clusters and placement using specific algorithms in Unreal Engine. The final deliverables should be the documentation that describes the entire pipeline, related tools, and source code, as well as an interactive demonstration of Tang Dynasty cityscape.</p>

      <br />
      <ul>
        <li style="padding-left:1em">• Project Duration: 14 weeks (Aug. 2022 - Dec. 2022)</li>
        <li style="padding-left:1em">• Tools: Unreal Engine 5, Houdini</li>
        <li style="padding-left:1em">• Platform: Console</li>
        <li style="padding-left:1em">• Roles: Programmer, Technical Artist</li>
      </ul>

      <hr />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/UO8O0E9P9dU" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Trailer</p>
      </div>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${ArtisanHalfsheetFront} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ArtisanHalfsheetBack} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <p style="font-size: 18px;">Halfsheet created by our artists</p>
      </div>

      <br />
      <p style="font-weight: bold">Notable Contributions</p>
      <ol>
        <li style="padding-left:1em">1. Designed and implemented procedural generation logic for road and city wall in Houdini.</li> 
        <li style="padding-left:1em">2. Built the automation terrain tool in Houdini and applied the tool in Unreal Engine 5 with built-in spline tool</li>
        <li style="padding-left:1em">3. Produce video materials and game demos for playtest sessions and presentations.</li>
      </ol>

      <br />
      <div style="padding: 24px; text-align: center">
        <div style="text-align: center;">
          <iframe width="480" height="315" src="https://www.youtube.com/embed/WfZijeSyBk0" frameborder="0" allowfullscreen></iframe>
          <iframe width="480" height="315" src="https://www.youtube.com/embed/OtFQe7LxMZ4" frameborder="0" allowfullscreen></iframe>
        </div>
        <p style="font-size: 18px;">Procedural Generation Tools (Road and Wall)</p>
      </div>

      <br />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/9ABtJMLFxkk" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Terrain procedural generation</p>
      </div>

      <br />
      <div style="width: 100%;">
        <p style="font-weight: bold">External Link</p>
        <ul>
          <li style="padding-left:1em">• <a href="https://projects.etc.cmu.edu/artisan/">project website</a></li>
        </ul>
      </div>
    </div>`,
  tags: ['game', 'project'],
  url: 'artisan',
  image: ArtisanCover,
  timeStamp: '2023-01-31',
};
