import DearAmbrosiaCover from '../../assets/images/portfolio/dearAmbrosia/coverphoto.png';
import DearAmbrosiaImgOne from '../../assets/images/portfolio/dearAmbrosia/01.png';
import DearAmbrosiaImgTwo from '../../assets/images/portfolio/dearAmbrosia/02.png';
import DearAmbrosiaImgThree from '../../assets/images/portfolio/dearAmbrosia/03.png';
import DearAmbrosiaImgFour from '../../assets/images/portfolio/dearAmbrosia/04.png';

export const dearAmbrosia = {
  title: 'Dear Ambrosia',
  subtitle: "Could you win the princess's heart back?",
  contents: `<img id="cover" src=${DearAmbrosiaCover} />
    <div>
      <p style="font-weight: bold">Build Virtual World - Round 2</p>
      <p>The goal of this game is to create an AR experience with feeling of freedom for naive guests</p>
      <br />
      <p>In this game, we have created a tilting board game that allows players to control the main character (a marble prince) by adjusting the board's angle and inclination. To expand the game's scope, we have designed the board as a two-part maze. Inside the maze, players can collect various items and try to locate the princess.</p>
      <br />
      <p>Moreover, the game's storyline is centered on winning the princess's heart back by selecting the perfect gift after finding her.</p>

      <br />
      <ul>
        <li style="padding-left:1em">• Project Duration: 2 weeks (Sept. 22, 2021 - Oct. 5, 2021)</li>
        <li style="padding-left:1em">• Tools: Unity, Mixed Reality Toolkit (MRTK)</li>
        <li style="padding-left:1em">• Platform: PC game / Augmented Reality (Hololens 2)</li>
        <li style="padding-left:1em">• Role: Programmer</li>
      </ul>

      <hr />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/gNFfuPXHHKE" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Trailer</p>
      </div>

      <br />
      <p style="font-weight: bold">Notable Contributions</p>
      <ol>
        <li style="padding-left:1em">1. Implemented grabbing and tilting system with Unity 3D and Microsoft's <a href='https://github.com/microsoft/MixedRealityToolkit-Unity'>Mixed Reality Toolkit</a></li>
        <li style="padding-left:1em">2. Adjusted physics configuration and collision mechanisms to smooth player's gaming experience</li>
        <li style="padding-left:1em">3. Designed game mechanisms including items collection behavior, split-and-merge tools, and the effect of growing flowers to increase players' immersive experience</li>
      </ol>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${DearAmbrosiaImgOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${DearAmbrosiaImgTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${DearAmbrosiaImgThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${DearAmbrosiaImgFour} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <p style="font-weight: bold">External Link</p>
      <ul>
      <li style="padding-left:1em">• <a href="https://www.youtube.com/watch?v=gNFfuPXHHKE">Youtube</a></li>
      <li style="padding-left:1em">• <a href="https://github.com/weijchen/DearAmbrosia">github</a></li>
      </ul>
    </div>`,
  tags: ['game', 'bvw'],
  url: 'dear-ambrosia',
  image: DearAmbrosiaCover,
  timeStamp: '2021-10-06',
};
