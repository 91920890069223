import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import Full from './containers/Full'

function App(props) {
  return (
    <Switch>
      <Route path="/" name="Full" component={Full} />
    </Switch>
  )
}

export default App
