import { aDayInTheLifeOfAGirl } from './aDayInTheLifeOfAGirl'
import { colorFinding } from './colorFinding'
import { dearAmbrosia } from './dearAmbrosia'
import { escalien } from './escalien'
import { keepTheDungeon } from './keepTheDungeon'
import { oneMoreDay } from './oneMoreDay'
import { patatapApp } from './patatapApp'
import { psac } from './psac'
import { submarush } from './submarush'
import { surveyCookies } from './surveyCookies'
import { todolist } from './todolist'
import { tornadoHero } from './tornadoHero'
import { tRun } from './tRun'
import { arviation } from './arviation'
import { artisan } from './artisan'

export const portfolios = {
  '/portfolio/artisan': artisan,
  '/portfolio/survey-cookies': surveyCookies,
  '/portfolio/bam-build-a-mustang': arviation,
  '/portfolio/a-day-in-the-life-of-a-girl': aDayInTheLifeOfAGirl,
  '/portfolio/submarush': submarush,
  '/portfolio/one-more-day': oneMoreDay,
  '/portfolio/t-run': tRun,
  '/portfolio/dear-ambrosia': dearAmbrosia,
  '/portfolio/tornado-hero': tornadoHero,

  // '/portfolio/patatap-app': patatapApp,
  // '/portfolio/todo-list': todolist,
  // '/portfolio/escalien': escalien,
  // '/portfolio/color-finding': colorFinding,
  // '/portfolio/keep-the-dungeon': keepTheDungeon,
  // '/portfolio/psac': psac,

}
