import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../../components/Home/Home'

function Home(params) {
  return <Layout />
}

Home.propTypes = {}

export default Home
