import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    borderRadius: '5px',
    '&:hover': {
      boxShadow: '0 3px 5px 0 #750606',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#CC0606',
  },
}))

function PortfolioCard(props) {
  // contents will be display in portfolio detail page
  const { title, subtitle, contents, tags, url, image, timestamp } = props
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [portfolioTag, setPortfolioTag] = useState('')

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (!Array.isArray(tags)) return
    setPortfolioTag(tags.join(' / '))
  }, [tags])

  return (
    <>
      <Link
        to={{ pathname: `/portfolio/${url}`, state: { contents: contents } }}
        style={{ textDecoration: 'none' }}
      >
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {title[0]}
              </Avatar>
            }
            title={`${title}`}
            subheader={`${portfolioTag}`}
          />
          <CardMedia className={classes.media} image={image} title="Paella dish" />
          <CardContent>
            <Typography variant="subtitle2" color="textSecondary" component="p">
              {subtitle}
            </Typography>
            <Typography variant="overline" color="textSecondary" component="p" align="right">
              {timestamp}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </>
  )
}

export default PortfolioCard
