import TornadoHeroCover from '../../assets/images/portfolio/tornadoHero/coverphoto.png';
import TornadoHeroImgOne from '../../assets/images/portfolio/tornadoHero/01.PNG';
import TornadoHeroImgTwo from '../../assets/images/portfolio/tornadoHero/02.PNG';
import TornadoHeroImgThree from '../../assets/images/portfolio/tornadoHero/03.PNG';
import TornadoHeroImgFour from '../../assets/images/portfolio/tornadoHero/04.PNG';

export const tornadoHero = {
  title: 'Tornado Hero',
  subtitle: 'A Tornado hit our country! We need a Hero to help us.',
  contents: `<img id="cover" src=${TornadoHeroCover} />
  <div>
    <p style="font-weight: bold">Build Virtual World - Round 1</p>
    <p>The goal of this game is to create an experience that involves two characters (A and B), with the player's objective being to help character A who is afraid of character B.</p>

    <br />
    <p>To achieve this, we have developed a virtual reality (VR) game where a city is struck by a massive tornado (character B). The player (guest) assumes the role of a hero who has decided to confront the damage caused by the tornado and save innocent citizens (character A). The player is equipped with two weapon systems: hooks to catch flying people, or metal bats to hit obstacles hurled by the tornado. The game's core mechanism is the dual interaction of using both tools to aid character A.</p>

    <br />
    <ul>
      <li style="padding-left:1em">• Project Duration: 2 weeks (Sep 7, 2021 - Sep 21, 2021)</li>
      <li style="padding-left:1em">• Tools: Unity, Oculus Quest 2</li>
      <li style="padding-left:1em">• Platform: PC game / Virtual Reality (Oculus)</li>
      <li style="padding-left:1em">• Role: Programmer</li>
    </ul>

    <hr />
    <div style="text-align: center;">
      <iframe width="640" height="480" src="https://www.youtube.com/embed/R8TJqLKaZ9k" frameborder="0" allowfullscreen></iframe>
      <p style="font-size: 18px;">Trailer</p>
    </div>

    <br />
    <p style="font-weight: bold">Notable Contributions</p>
    <ol>
      <li style="padding-left:1em">1. Implemented customized input system with Unity 3D and Oculus Quest 2 for grabbing/gripping functions and for tools spawning mechanism</li>
      <li style="padding-left:1em">2. Implemented the physical mechanism of the bat hitting effect, game object flying system, and hook's rope mechanism</li>
      <li style="padding-left:1em">3. Built intro scene, tutorial section, and UI components to help players enjoy the game more easily</li>
      <li style="padding-left:1em">4. Brainstormed the game idea and designed the gameplay mechanics with team members</li>
    </ol>

    <br />
    <div style="padding: 24px; text-align: center">
      <img src=${TornadoHeroImgOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      <img src=${TornadoHeroImgTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
    </div>
    <div style="padding: 24px; text-align: center">
      <img src=${TornadoHeroImgThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      <img src=${TornadoHeroImgFour} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
    </div>

    <br />
    <p style="font-weight: bold">External Link</p>
    <ul>
    <li style="padding-left:1em">• <a href="https://weijchen0919.itch.io/tornado-hero">Itch.io</a></li>
    <li style="padding-left:1em">• <a href="https://www.youtube.com/watch?v=R8TJqLKaZ9k&feature=youtu.be">Youtube</a></li>
    <li style="padding-left:1em">• <a href="https://github.com/weijchen/TornadoHero">github</a></li>
    </ul>
  </div>`,
  tags: ['game', 'bvw'],
  url: 'tornado-hero',
  image: TornadoHeroCover,
  timeStamp: '2021-9-23',
};
