import React, { Suspense } from 'react'
import { Route, Switch, useHistory, Redirect } from 'react-router-dom'

import Header from '../components/UI/Header'
import Footer from '../components/UI/Footer'

import role_permission from './role_permission'

function Full(props) {
  return (
    <main>
      <Suspense fallback={<div></div>}>
        <Header />
        <Switch>
          {role_permission['Role'].normRoute.map((route) => {
            return <Route sensitive path={route.path} key={route.name} name={route.name} component={route.component} />
          })}
          {role_permission['Role'].absRoute.map((route) => {
            return (
              <Route sensitive exact path={route.path} key={route.name} name={route.name} component={route.component} />
            )
          })}
          <Redirect from="*" to="/" />
        </Switch>
        <Footer />
      </Suspense>
    </main>
  )
}

export default Full
