import React, { useState, useEffect } from 'react'
import AOS from 'aos'

import About from './HomeInfo/About'
import EduAndExp from './HomeInfo/EduAndExp'

import SkillDataIcon from '../../assets/icons/skillData.svg'
import SkillGameIcon from '../../assets/icons/skillGame.svg'
import SkillWebIcon from '../../assets/icons/skillWeb.svg'
import SkillProgrammingIcon from '../../assets/icons/skillProgramming.svg'
import EmailMeIcon from '../../assets/icons/emailMe.svg'
import HomeBackgroundXL from '../../assets/images/landing-background-xl.jpg'
import HomeBackgroundLG from '../../assets/images/landing-background-lg.jpg'
import HomeBackgroundMD from '../../assets/images/landing-background-md.jpg'

function Home(props) {
  const [emailSubject, setEmailSubject] = useState('')
  const [emailBody, setEmailBody] = useState('')

  const autoHeight = () => {
    var element = document.getElementById('input-message')
    element.style.height = 'auto'
    element.style.height = element.scrollHeight + 'px'
  }

  const handleEmailFormSubmit = (e) => {
    e.preventDefault()
    window.open(`mailto:jimmy.weicc@gmail.com?subject=${emailSubject}&body=${emailBody}`)
  }

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    })
  })

  return (
    <>
      <div className="__home">
        <div className="__home__top__img cus-container">
          {/* <picture>
            <source srcset={HomeBackgroundXL} media="(min-width: 1440px)" />
            <source srcset={HomeBackgroundLG} media="(min-width: 720px)" />
            <img src={HomeBackgroundMD} alt="home-top-img" />
          </picture> */}
        </div>
        <About />
        <EduAndExp />
        <div className="__home__skill cus-container">
          <div className="__home__skill__container">
            <div className="__home__skill-title __home__section__title">
              <p>Skill</p>
            </div>
            <div className="skill-container d-flex">
              <div className="skill-section col-12 col-md-3" data-aos="fade-up">
                <img src={SkillProgrammingIcon} alt="skill-web-img" />
                <p className="title">Programming</p>
                <p className="desc">Java, Python, JavaScript/TypeScript, C#, HTML, CSS/SCSS, SQL</p>
              </div>
              <div className="skill-section col-12 col-md-3" data-aos="fade-up">
                <img src={SkillWebIcon} alt="skill-framework-img" />
                <p className="title">Frameworks</p>
                <p className="desc">Spring Framework (Core, Boot, MVC, Security, REST, AOP, Cloud), Hibernate, Node/Express, Microservice, React, Redux, Angular, Bootstrap, JUnit, Mockito, J2EE, Unity, PyTorch, TensorFlow</p>
              </div>
              <div className="skill-section col-12 col-md-3" data-aos="fade-up">
                <img src={SkillGameIcon} alt="skill-database-tools-img" />
                <p className="title">Database & Tools</p>
                <p className="desc">MySQL, PostgreSQL, MongoDB, JDBC, Postman, Git, AMQP (Rabbit MQ), Maven, AWS (RDS, S3, IAM), Docker, JIRA</p>
              </div>
              <div className="skill-section col-12 col-md-3" data-aos="fade-up">
                <img src={SkillDataIcon} alt="skill-data-img" />
                <p className="title">Others</p>
                <p className="desc">Figma, VS Code, Visual Studio, Heroku</p>
              </div>
            </div>
          </div>
        </div>
        <div className="__home__contact cus-container d-flex">
          <div className="__home__contact__container row m-auto">
            <div className="__home__contact-email-img col-md-4" data-aos="slide-right">
              <img src={EmailMeIcon} alt="email-me-icon" />
            </div>
            <div className="__home__contact-email col-12 col-md-8 m-auto">
              <div className="title">
                <p>Contact Me</p>
              </div>
              <form onSubmit={handleEmailFormSubmit}>
                {/* <div className="input-field">
                  <lable className="form-name">Name</lable>
                  <br />
                  <input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="input-field">
                  <lable className="form-email">Email</lable>
                  <br />
                  <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div> */}
                <div className="input-field">
                  <lable className="form-subject">Subject</lable>
                  <br />
                  <input placeholder="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} />
                </div>
                <div className="input-field">
                  <lable className="form-subject">Content</lable>
                  <br />
                  <textarea
                    id="input-message"
                    placeholder="Enter your message"
                    value={emailBody}
                    rows="2"
                    style={{ resize: 'none' }}
                    onKeyUp={() => autoHeight()}
                    onChange={(e) => setEmailBody(e.target.value)}
                  ></textarea>
                </div>
                <button className="btn btn-small">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Home.propTypes = {}

export default Home
