import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import profilePhoto from '../../../assets/images/profile_2.jpeg';
import { Divider } from '@material-ui/core';
import SkillGameIcon from '../../../assets/icons/skillGame.svg';

function About() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  });

  return (
    <>
      <div className="__about cus-container">
        <div className="__about__textarea">
          {/* <div className="__about__title">
            <p>About</p>
          </div> */}

          <div className="__about__contents" data-aos="fade-up">
            <div className="__about__left col-12 col-md-6">
              <div className="__about__profile__photo">
                <img src={profilePhoto} alt="" />
              </div>
            </div>
            <div className="__about__right col-12 col-md-6">
              <p>Hi, I am Jimmy Chen, and my chinese name is 威政 (Wei-Cheng). I have been working as a software engineer in web development and data science for approximately 4 years. </p>
              <br />
              <p>
                Please feel free to contact me via{' '}
                <a href="mailto: jimmy.weicc@gmail.com">email</a> if you are interested in my experiences and previous projects
              </p>
              <p>
                For more information about my background and experiences, please refer to my updated{' '}
                <a href="https://drive.google.com/file/d/1wVXZlE-sFDwxNSZJHbRjzbz--67yDA4G/view?usp=sharing">CV</a> (last updated on
                Aug. 23).
              </p>
            </div>
          </div>
          <div className="__about__footer" data-aos="fade-up"></div>
        </div>
      </div>
    </>
  );
}

export default About;
