import EscalienCover from "../../assets/images/portfolio/escalien/coverphoto.png";
import EscalienOne from "../../assets/images/portfolio/escalien/01.PNG";
import EscalienTwo from "../../assets/images/portfolio/escalien/02.PNG";
import EscalienThree from "../../assets/images/portfolio/escalien/03.PNG";
import EscalienFour from "../../assets/images/portfolio/escalien/04.PNG";

export const escalien = {
  title: "Escalien",
  subtitle: '"Greenies" invade the planet, use grenades to blow them off!',
  contents: `<img id="cover" src=${EscalienCover} />
  <div>
    <p style="font-weight: bold">Personal Game Project</p>
    <ul>
      <li style="padding-left:1em">• Project Duration: 1 weeks (May 09, 2020 - May 16, 2020)</li>
      <li style="padding-left:1em">• Tools: Unity, MagicaVoxel</li>
      <li style="padding-left:1em">• Platform: PC game</li>
      <li style="padding-left: 2em">• Introduction: You wake up at a strange place surrounding by green aliens. Use grenades to blow them off and find a way to escape!</ul>

    <div style="padding: 24px; text-align: center">
      <img src=${EscalienOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      <img src=${EscalienTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
    </div>
    <br />
    <p style="font-weight: bold">Notable Contributions</p>

    <ol>
      <li style="padding-left:1em">1. Implemented Player Controller and Physics reactions (collision, trigger)</li>
      <li style="padding-left:1em">2. Used mini map to show the guest their position in the world</li>
      <li style="padding-left:1em">3. Designed and created character and grenade models with MagicaVoxel</li>
    </ol>

    <div style="padding: 24px; text-align: center">
      <img src=${EscalienThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      <img src=${EscalienFour} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
    </div>
    <br />
    <p style="font-weight: bold">External Link</p>
    <ul>
    <li style="padding-left:1em">• <a href="https://www.youtube.com/watch?v=8ClXd45vSd8&feature=youtu.be">Youtube</a></li>
    <li style="padding-left:1em">• <a href="https://github.com/weijchen/Escalien">github</a></li>
    <li style="padding-left:1em">• <a href="https://weijchen0919.itch.io/escalien">itch.io</a></li>
    </ul>
  </div>`,
  tags: ["game"],
  url: "escalien",
  image: EscalienCover,
  timeStamp: "2020-05-09",
};
