import Home from './views/Home/Home'
import Portfolio from './views/Portfolio/Portfolio'
import PortfolioInfo from './views/Portfolio/PortfolioInfo'

const Role = {
  normRoute: [{ path: '/portfolio/:id', name: 'portfolioInfo', component: PortfolioInfo }],
  absRoute: [
    { path: '/portfolio', name: 'portfolio', component: Portfolio },
    { path: '/', name: 'home', component: Home },
  ],
}

export default {
  Role,
}
