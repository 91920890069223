import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function PortfolioInfo(props) {
  const { portfolioTitle, portfolioContents } = props
  return (
    <>
      <div className="__portfolio__info__title cus-container">{portfolioTitle ?? portfolioTitle}</div>
      <div className="__portfolio__info__container cus-container">
        <div className="__portfolio__info__contents" dangerouslySetInnerHTML={{ __html: portfolioContents }}></div>
      </div>
    </>
  )
}

export default PortfolioInfo
