import TRunCover from '../../assets/images/portfolio/tRun/coverphoto.jpg';
import TRunImgOne from '../../assets/images/portfolio/tRun/01.png';
import TRunImgTwo from '../../assets/images/portfolio/tRun/02.png';
import TRunImgThree from '../../assets/images/portfolio/tRun/03.png';
import TRunImgFour from '../../assets/images/portfolio/tRun/04.png';
import TRunImgFive from '../../assets/images/portfolio/tRun/05.png';
import TRunImgSix from '../../assets/images/portfolio/tRun/06.png';
import TRunImgSeven from '../../assets/images/portfolio/tRun/07.png';
import TRunImgEight from '../../assets/images/portfolio/tRun/08.png';

export const tRun = {
  title: 'T-Run',
  subtitle: 'Cyberpunk inspired racing game',
  contents: `<img id="cover" src=${TRunCover} />

    <div>
      <p style="font-weight: bold">Build Virtual World - Round 3</p>

      <br />
      <p>For this project, each team has been tasked with creating a virtual world focused on fun, with a one-week deadline.</p> 

      <br />
      <p>Despite the challenge, we have decided to develop a game inspired by TRON and other endless running games such as Temple Run and Subway Surfers. Additionally, we have incorporated cyberpunk style and theme into the game. The result is a racing game where players ride a cyber motorcycle through jump platforms and tracks.</p>

      <br />
      <p>To enhance the playing experience, we have utilized various camera angles and perspectives, and polished the motorcycle model with particle systems and complex movement behaviors. The game's objective is to finish the track with higher scores (with diamond-shaped collectibles) and a faster finish time.</p>

      <br />
      <ul>
        <li style="padding-left:1em">• Project Duration: 1 weeks (Oct. 6, 2021 - Oct. 12, 2021)</li>
        <li style="padding-left:1em">• Tools: Unity, Tobii Eye Tracker</li>
        <li style="padding-left:1em">• Platform: PC game / Tobii Eye Tracker</li>
        <li style="padding-left:1em">• Role: Programmer</li>
      </ul>

      <hr />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/WbWmG5hnUB4" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Trailer</p>
      </div>

      <br />
      <p style="font-weight: bold">Notable Contributions</p>
      <ol>
        <li style="padding-left:1em">1. Implemented the player controller and adjusted physics reactions for the cyber motorcycle on Tobii Eye Tracker 5 with Unity SDK</li>
        <li style="padding-left:1em">2. Responsible for the level design of a three-stage scene, including the racing track and collectibles</li>
        <li style="padding-left:1em">3. Collaborated with artists on the arrangement of interactable objects in the game (collectibles, respawn points, user interface, and dead zone)</li>
      </ol>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${TRunImgOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${TRunImgTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${TRunImgThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${TRunImgFour} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${TRunImgFive} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${TRunImgSix} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${TRunImgSeven} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${TRunImgEight} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <p style="font-weight: bold">External Link</p>
      <ul>
      <li style="padding-left:1em">• <a href="https://www.youtube.com/watch?v=SWlcSguHVSs">Youtube</a></li>
      <li style="padding-left:1em">• <a href="https://github.com/weijchen/TRun">github</a></li>
      </ul>
    </div>`,
  tags: ['game', 'bvw'],
  url: 't-run',
  image: TRunCover,
  timeStamp: '2021-10-14',
};
