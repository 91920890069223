import KeepTheDungeonMainImg from '../../assets/images/portfolio/keepTheDungeonMain.png';

export const keepTheDungeon = {
  title: 'Keep the Dungeon',
  subtitle: 'Warrior! Arm yourself and eliminate enemies coming from everywhere!',
  contents: `<img src=${KeepTheDungeonMainImg} /><div>Warrior! Arm yourself and eliminate enemies coming from everywhere!<div>`,
  tags: ['game'],
  url: 'keep-the-dungeon',
  image: KeepTheDungeonMainImg,
  timeStamp: '2021-9-23',
};
