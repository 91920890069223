import Psac19MainImg from '../../assets/images/portfolio/psac19Main.jpg';

export const psac = {
  title: 'PSAC: Context-based purchase prediction framework via user’s sequential actions',
  subtitle: 'WC Chen, CY Wang, SC Lin, A Ou, TC Liou - eCOM@ SIGIR, 2019',
  contents: `<img src=${Psac19MainImg} /><div>WC Chen, CY Wang, SC Lin, A Ou, TC Liou - eCOM@ SIGIR, 2019<div>`,
  tags: ['data'],
  url: 'psac',
  image: Psac19MainImg,
  timeStamp: '2021-9-23',
};
