import { tornadoHero } from './portfolios/tornadoHero';
import { dearAmbrosia } from './portfolios/dearAmbrosia';
import { tRun } from './portfolios/tRun';
import { oneMoreDay } from './portfolios/oneMoreDay';
import { submarush } from './portfolios/submarush';
import { aDayInTheLifeOfAGirl } from './portfolios/aDayInTheLifeOfAGirl';
import { patatapApp } from './portfolios/patatapApp';
import { keepTheDungeon } from './portfolios/keepTheDungeon';
import { colorFinding } from './portfolios/colorFinding';
import { escalien } from './portfolios/escalien';
import { surveyCookies } from './portfolios/surveyCookies';
import { todolist } from './portfolios/todolist';
import { psac } from './portfolios/psac';
import { arviation } from './portfolios/arviation';
import { artisan} from './portfolios/artisan';

export const portfolios = [
  artisan,
  surveyCookies,
  arviation,
  aDayInTheLifeOfAGirl,
  submarush,
  oneMoreDay,
  tRun,
  dearAmbrosia,
  tornadoHero,

  // todolist,
  // patatapApp,
  // escalien,
  // keepTheDungeon,
  // colorFinding,
  // psac,
];
