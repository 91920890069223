import ADayInTheLifeOfAGirlCover from '../../assets/images/portfolio/aDayInTheLifeOfAGirl/coverphoto.png';
import ADayInTheLifeOfAGirlImgOne from '../../assets/images/portfolio/aDayInTheLifeOfAGirl/01.png';
import ADayInTheLifeOfAGirlImgTwo from '../../assets/images/portfolio/aDayInTheLifeOfAGirl/02.png';
import ADayInTheLifeOfAGirlImgThree from '../../assets/images/portfolio/aDayInTheLifeOfAGirl/03.png';
import ADayInTheLifeOfAGirlImgFour from '../../assets/images/portfolio/aDayInTheLifeOfAGirl/04.png';

export const aDayInTheLifeOfAGirl = {
  title: 'A Day in the Life of a Girl',
  subtitle: 'A story about love',
  contents: `<img id="cover" src=${ADayInTheLifeOfAGirlCover} />
    <div>
      <p style="font-weight: bold">Visual Story - Round 2</p>

      <br />
      <p>Interactive/Non-linear - Produce an interactive story using the following title "A day in the life of ___"</p>

      <br />
      <p>To create a user-driven experience, we have designed a game inspired by Florence, a well-known interactive story and video game. Players will experience a day in a girl's life and face some difficult times.</p>

      <br />
      <p>Several interactive mechanisms have been implemented to help players immerse themselves in the game, including clickable items, scratch-off images, and sliding scenes.</p>

      <br />
      <ul>
        <li style="padding-left:1em">• Project Duration: 3 weeks (Oct. 8, 2021 - Oct. 29, 2021)</li>
        <li style="padding-left:1em">• Tools: Unity</li>
        <li style="padding-left:1em">• Platform: PC game</li>
        <li style="padding-left:1em">• Role: Programmer</li>
      </ul>

      <hr />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/3vpz2KbrjAo" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Trailer</p>
      </div>

      <br />
      <p style="font-weight: bold">Notable Contributions</p>
      <ol>
        <li style="padding-left:1em">1. Designed and implemented interactive mechanisms (clickable items, scratch-off effect, zoom-in/out animation, etc)</li>
        <li style="padding-left:1em">2. Brainstormed storylines and characterize the personality of our character with other team members</li>
        <li style="padding-left:1em">3. Strengthen the playing experience by adding background music and SFX</li>
      </ol>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${ADayInTheLifeOfAGirlImgOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ADayInTheLifeOfAGirlImgTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${ADayInTheLifeOfAGirlImgThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${ADayInTheLifeOfAGirlImgFour} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <p style="font-weight: bold">External Link</p>
      <ul>
      <li style="padding-left:1em">• <a href="https://www.youtube.com/watch?v=3vpz2KbrjAo">Youtube</a></li>
      <li style="padding-left:1em">• <a href="https://github.com/weijchen/A_Day_in_the_Life_of_a_Girl">github</a></li>
      </ul>
    </div>`,
  tags: ['game', 'vs'],
  url: 'a-day-in-the-life-of-a-girl',
  image: ADayInTheLifeOfAGirlCover,
  timeStamp: '2021-11-24',
};
