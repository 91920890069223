import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../../components/Portfolio/PortfolioInfo';
import { portfolios } from '../../../constants/portfolios/portfolios';

function PortfolioInfo(params) {
  const [portfolioTitle, setPortfolioTitle] = useState(null);
  const [portfolioContents, setPortfolioContents] = useState(null);
  const location = useLocation();

  useEffect(() => {
    var portfolioContent = null;
    if (portfolios[location.pathname]) {
      portfolioContent = portfolios[location.pathname].contents;
    } else {
      portfolioContent = location.state.contents;
    }
    setPortfolioContents(portfolioContent);
  }, [location]);

  useEffect(() => {
    const title = portfolios[location.pathname]['title'];
    setPortfolioTitle(title);
  }, [location]);

  return <Layout portfolioTitle={portfolioTitle} portfolioContents={portfolioContents} />;
}

PortfolioInfo.propTypes = {};

export default PortfolioInfo;
