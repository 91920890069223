import SubmarushCover from '../../assets/images/portfolio/submarush/coverphoto.png';
import SubmarushImgOne from '../../assets/images/portfolio/submarush/01.png';
import SubmarushImgTwo from '../../assets/images/portfolio/submarush/02.png';
import SubmarushImgThree from '../../assets/images/portfolio/submarush/03.png';
import SubmarushImgFour from '../../assets/images/portfolio/submarush/04.png';
import SubmarushImgFive from '../../assets/images/portfolio/submarush/05.png';
import SubmarushImgSix from '../../assets/images/portfolio/submarush/06.png';

export const submarush = {
  title: 'Submarush',
  subtitle: 'Multiplayer racing competition',
  contents: `<img id="cover" src=${SubmarushCover} />

    <div>
      <p style="font-weight: bold">Build Virtual World - Round 5</p>

      <br />
      <p>This is the final round of BVW. To prepare for the annual event in our department - Festival, each team has been tasked with creating a game that combines novelty, social gameplay, and accessibility.</p>

      <br />
      <p>In this game, each player is a mecha pilot trapped in an underwater lab. The mission is to escape the tunnel before it's too late, but other players are also trying to escape. Players must race to drive their mechas faster than their opponents.</p>

      <br />
      <p>Our game utilizes tee ball stand as the mechas' controller. Tee ball stand's flexibility in 2D shifting allows for smooth control for the players. On top of the stand, we attached a Vive tracker to capture the stand's movement. Additionally, we have built a pedal using Makey Makey to handle the mechas' engine operation.</p>

      <br />
      <ul>
        <li style="padding-left:1em">• Project Duration: 2 weeks (Nov. 3, 2021 - Nov. 16, 2021)</li>
        <li style="padding-left:1em">• Tools: Unity3D, Vive Tracker, Makey Makey</li>
        <li style="padding-left:1em">• Platform: PC game / Vive Tracker, Makey Makey</li>
        <li style="padding-left:1em">• Role: Programmer</li>
      </ul>

      <hr />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/sjt_l4KSwEA" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Trailer</p>
      </div>

      <br />
      <p style="font-weight: bold">Notable Contributions</p>
      <ol>
        <li style="padding-left:1em">1. Implemented the player controller (physically and programmatically) with Vive tracker and Makey Makey for the Mecha</li>
        <li style="padding-left:1em">2. Designed the tunnel layout and obstacle mechanisms (Lazer, door with interactable button, and rotating fan)</li>
        <li style="padding-left:1em">3. Built the movement of Boids (splitting groups and wild behavior) for the fish to intensify the player's immersive experience of the underwater environment</li>
      </ol>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${SubmarushImgOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${SubmarushImgTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${SubmarushImgThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${SubmarushImgFour} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${SubmarushImgFive} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${SubmarushImgSix} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <p style="font-weight: bold">External Link</p>
      <ul>
      <li style="padding-left:1em">• <a href="https://www.youtube.com/watch?v=sjt_l4KSwEA">Youtube</a></li>
      <li style="padding-left:1em">• <a href="https://github.com/weijchen/Submarush">github</a></li>
      </ul>
    </div>`,
  tags: ['game', 'bvw'],
  url: 'submarush',
  image: SubmarushCover,
  timeStamp: '2021-11-24',
};
