import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import PropTypes from 'prop-types';

import PortfolioCard from '../UI/PortfolioCard';
import { portfolios } from '../../constants/fakePortfolio';

import PortfolioLandingImg from '../../assets/images/portfolio/portfolioLanding.svg';

const classNames = require('classnames');

function Portfolio(props) {
  const [currentTag, setCurrentTag] = useState('all');

  const CategoryTagBtnOnClick = (tagValue) => {
    setCurrentTag(tagValue);
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 750,
      once: true,
    });
  });

  return (
    <>
      <div className="__portfolio__landing">
        <img src={PortfolioLandingImg} alt="portfolio-landing-img" data-aos="zoom-in" />
      </div>
      <div className="__portfolio__category">
        <div className="d-flex">
          <button
            className={classNames('__portfolio__category-tag btn btn-sm', currentTag === 'all' ? 'active' : '')}
            onClick={() => CategoryTagBtnOnClick('all')}
            data-aos="flip-left"
          >
            All
          </button>
          <button
            className={classNames('__portfolio__category-tag btn btn-sm', currentTag === 'web' ? 'active' : '')}
            onClick={() => CategoryTagBtnOnClick('web')}
            data-aos="flip-left"
          >
            Web
          </button>
          <button
            className={classNames('__portfolio__category-tag btn btn-sm', currentTag === 'game' ? 'active' : '')}
            onClick={() => CategoryTagBtnOnClick('game')}
            data-aos="flip-left"
          >
            Game
          </button>
          <button
            className={classNames('__portfolio__category-tag btn btn-sm', currentTag === 'data' ? 'active' : '')}
            onClick={() => CategoryTagBtnOnClick('data')}
            data-aos="flip-left"
          >
            Data
          </button>
          <button
            className={classNames('__portfolio__category-tag btn btn-sm', currentTag === 'bvw' ? 'active' : '')}
            onClick={() => CategoryTagBtnOnClick('bvw')}
            data-aos="flip-left"
          >
            BVW
          </button>
          <button
            className={classNames('__portfolio__category-tag btn btn-sm', currentTag === 'vs' ? 'active' : '')}
            onClick={() => CategoryTagBtnOnClick('vs')}
            data-aos="flip-left"
          >
            Visual Story
          </button>
          <button
            className={classNames('__portfolio__category-tag btn btn-sm', currentTag === 'project' ? 'active' : '')}
            onClick={() => CategoryTagBtnOnClick('project')}
            data-aos="flip-left"
          >
            Project
          </button>
        </div>
      </div>
      <div className="__portfolio__container cus-container">
        <div className="row">
          {!!portfolios && portfolios.map((ele, ind) => {
            return (
              <div
                key={ind}
                className={classNames(
                  'col-12 col-md-6 col-lg-4 p-3 __portfolio__container-card',
                  currentTag === 'all' ? 'active' : ele.tags.includes(currentTag) ? 'active' : 'hidden',
                )}
              >
                <PortfolioCard
                  title={ele.title}
                  subtitle={ele.subtitle}
                  contents={ele.contents}
                  tags={ele.tags}
                  url={ele.url}
                  image={ele.image}
                  timestamp={ele.timeStamp}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

Portfolio.propTypes = {};

export default Portfolio;
