import OneMoreDayCover from '../../assets/images/portfolio/oneMoreDay/cover.jpg';
import OneMoreDayImgOne from '../../assets/images/portfolio/oneMoreDay/01.png';
import OneMoreDayImgTwo from '../../assets/images/portfolio/oneMoreDay/02.png';
import OneMoreDayImgThree from '../../assets/images/portfolio/oneMoreDay/03.png';
import OneMoreDayImgFour from '../../assets/images/portfolio/oneMoreDay/04.png';
import OneMoreDayImgFive from '../../assets/images/portfolio/oneMoreDay/05.png';
import OneMoreDayImgSix from '../../assets/images/portfolio/oneMoreDay/06.png';
import OneMoreDayImgSeven from '../../assets/images/portfolio/oneMoreDay/07.png';
import OneMoreDayImgEight from '../../assets/images/portfolio/oneMoreDay/08.png';

export const oneMoreDay = {
  title: 'One More Day',
  subtitle: 'Discover the story behind the house',
  contents: `<img id="cover" src=${OneMoreDayCover} />

    <div>
      <p style="font-weight: bold">Build Virtual World - Round 4</p>

      <br />
      <p>The topic for the fourth round of project is to tell an interactive story</p>

      <br />
      <p>The game is inspired by the Chernobyl Nuclear Disaster. The player takes on the role of a mother who returns to her hometown to collect her daughter's toys. We have developed a suspenseful atmosphere using lighting and visual effects to intensify the player's experience in the world. At the end of the journey, the player discovers what happened in the house.</p> 

      <br />
      <ul>
        <li style="padding-left:1em">• Project Duration: 2 weeks (Oct. 19, 2021 - Nov. 2, 2021)</li>
        <li style="padding-left:1em">• Tools: Unity, Oculus Quest, Shader</li>
        <li style="padding-left:1em">• Platform: PC game / Virtual Reality (Oculus)</li>
        <li style="padding-left:1em">• Role: Programmer</li>
      </ul>

      <hr />
      <div style="text-align: center;">
        <iframe width="640" height="480" src="https://www.youtube.com/embed/gU9dQ8hPUU0" frameborder="0" allowfullscreen></iframe>
        <p style="font-size: 18px;">Trailer</p>
      </div>

      <br />
      <p style="font-weight: bold">Notable Contributions</p>
      <ol>
        <li style="padding-left:1em">1. Implemented and designed the player controller and interaction mechanisms</li>
        <li style="padding-left:1em">2. Set visual effect with lighting, particle system, and shader for the virtual world</li>
        <li style="padding-left:1em">3. Set the game scene, storyline, game UI, objects interaction, and scene transition</li>
      </ol>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${OneMoreDayImgOne} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${OneMoreDayImgTwo} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${OneMoreDayImgThree} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${OneMoreDayImgFour} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <div style="padding: 24px; text-align: center">
        <img src=${OneMoreDayImgFive} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${OneMoreDayImgSix} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>
      <div style="padding: 24px; text-align: center">
        <img src=${OneMoreDayImgSeven} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
        <img src=${OneMoreDayImgEight} style="margin: 0px 2px; height: 100%; width: 100%; object-fit: contain"/>
      </div>

      <br />
      <p style="font-weight: bold">External Link</p>
      <ul>
      <li style="padding-left:1em">• <a href="https://www.youtube.com/watch?v=gU9dQ8hPUU0">Youtube</a></li>
      <li style="padding-left:1em">• <a href="https://github.com/weijchen/OneMoreDay">github</a></li>
      </ul>
    </div>`,
  tags: ['game', 'bvw'],
  url: 'one-more-day',
  image: OneMoreDayCover,
  timeStamp: '2021-11-02',
};
